@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
body{
	font-family: 'Noto Sans JP';
}
.header{
	background: url(../img/visual.png) no-repeat center top;
	background-size: cover;
	height: 100vh;
	position: relative;
	&__flex{
		padding: 15px;
		display: flex;
		display: -webkit-flex;
		align-items: center;
	}
	&__logo{
		order: 1;
	}
	&__title{
		order: 2;
		margin-left: auto;
		letter-spacing: 0.1em;
		font-size: 12px;
		font-weight: bold;
	}
	&__wrap{
		width: 680px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		-webkit-transform: translate(-50%,-50%);
	}
	.hero{
		&__logo{
			margin-bottom: 80px;
			text-align: center;
		}
		&__catch{
			font-size: 40px;
			line-height: 60px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 40px;
		}
		&__text{
			text-align: center;
			font-size: 20px;
			line-height: 36px;
			letter-spacing: 0.1em;
		}
	}
	@media (max-width: 768px) {
		&__flex{
			padding: 7px;
			flex-direction: column;
		}
		&__logo{
			order: 2;
			display: none;
		}
		&__title{
			width: 100%;
			text-align: center;
			order: 1;
			margin-left: 0;
			font-size: 10px;
		}
		&__wrap{
			width: 90%;
		}
		.hero{
			&__logo{
				margin-bottom: 40px;
				img{
					width: 100%;
					height: auto;
					vertical-align: bottom;
				}
			}
			&__catch{
				font-size: 20px;
				line-height: 30px;
				margin-bottom: 20px;
			}
			&__text{
				font-size: 14px;
				line-height: 28px;
				text-align: left;
				br{display: none;}
			}
		}
	}
}

.content{
	&__inner{
		width: 1000px;
		margin: 0 auto;
		padding: 80px 0;
	}
	&__head{
		text-align: center;
		margin-bottom: 40px;
		position: relative;
		&:after{
			width: 100px;
			height: 3px;
			background: #db1b15;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			content: "";
		}
	}
	&__title{
		padding-bottom: 30px;
		font-size: 32px;
		font-weight: bold;
		span{
			display: block;
			font-family: 'Roboto', sans-serif;
			font-size: 12px;
			margin-top: 10px;
			color: #db1b15;
			font-weight: bold;
		}
	}
	&__table{
		tbody{
			tr{
				th{
					padding: 20px 0;
					width: 150px;
					text-align: left;
				}
				td{
					padding: 20px;
					line-height: 30px;
					>p{
						margin-bottom: 10px;
					}
					a{
						color: #db1b15;
					}
				}
			}
			tr+tr{border-top: 1px solid #ccc;}
		}
	}
	@media (max-width: 768px) {
		&__inner{
			width: 100%;
			padding: 40px 15px;
			box-sizing: border-box;
		}
		&__head{
			text-align: center;
			margin-bottom: 20px;
			position: relative;
			&:after{
				width: 50px;
				height: 2px;
				background: #db1b15;
			}
		}
		&__title{
			padding-bottom: 15px;
			font-size: 28px;
			span{
				font-size: 10px;
				margin-top: 10px;
			}
		}
		&__table{
			font-size: 14px;
			tbody{
				tr{
					th{
						padding: 10px 0;
						width: 100%;
						display: block;
						background: #f0f0f0;
						text-align: center;
						font-weight: bold;
					}
					td{
						padding: 10px 0;
						width: 100%;
						display: block;
						span{
							display: block;
						}
						>p{
							margin-bottom: 10px;
						}
						a{
							color: #db1b15;
						}
					}
				}
				tr+tr{border-top: none}
			}
		}
	}
}
#map01,#map02{
	height: 400px;
	margin-bottom: 30px;
	position: relative;
	iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	@media (max-width: 768px) {
		padding-top: 52%;
		height: auto;
		margin-bottom: 15px;
	}
}

footer{
	background: #db1b15;
	color: #fff;
	font-size: 12px;
	text-align: center;
	padding: 20px 0;
}